import React,{ useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

import { UserHeader } from '@/src/components-v3/headers/UserHeader';
import { UserMobileNavFooter } from '@/src/components-v3/footers/UserMobileNavFooter';
import { UserFooterNew } from '@/src/components-new/footer/userFooter';

import { NavTabs } from '@/src/atoms-v3/NavTabs';

import { UserFeedbackWidget } from '@/src/components-etc/user-feedback-widget/UserFeedbackWidget';

interface UserMainLayoutProps {
    className?: string;
    children?: JSX.Element | JSX.Element[];
    pageTitle?: string;
    bgColor?: string;
    backHandler?: () => void;

    //navtabs
    tabOptions?: {label: string, value: string}[];
    tabSelectHandler?: (val:string) => void;
    selectedTab?: string;
    bannerComponent?: JSX.Element | JSX.Element[];
}

export const UserMainLayout = ({
    className,
    children,
    pageTitle='',
    bgColor='bg-white',
    backHandler,

    //navtabs
    tabOptions,
    tabSelectHandler,
    selectedTab,
    bannerComponent
}: UserMainLayoutProps) => {

    const isBigScreen = useMediaQuery({ minWidth:1024 })

    
    /** HIDE USERFEEDBACK WIDGET IF SCROLL IS NEAR BOTTOM */
    const [isVisible, setIsVisible] = useState(true);
    const handleScroll = (setIsVisible: React.Dispatch<React.SetStateAction<boolean>>) => {
        const scrollPosition = window.innerHeight + window.scrollY;
        const threshold = document.body.offsetHeight - 100; // Adjust the threshold as needed
        if (scrollPosition >= threshold) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };
    useEffect(() => {
        const onScroll = () => handleScroll(setIsVisible);
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <div className='flex flex-col w-[calc(100dvw)] min-h-[calc(100dvh)]'>
            <div className='flex flex-col w-full h-full items-center relative'>
                <div className='w-full top-0 sticky z-[20]'> 
                    <UserHeader 
                        pageTitle={pageTitle}
                        backHandler={backHandler}
                    />
                </div>

                <div className={`flex flex-col w-full h-full items-center ${className}`}>
                    <div className='flex flex-col gap-0 w-full md:w-2/3 lg:w-2/3 xl:w-7/12 2xl:w-1/2'>
                        {   isBigScreen &&
                            <h1 className='my-4'>{pageTitle}</h1>
                        }
                        {   tabOptions && tabSelectHandler && selectedTab ? (
                            <NavTabs 
                                tabList={tabOptions}
                                onClick={tabSelectHandler}
                                selected={selectedTab}
                            />
                        ) : <></>
                        }
                        {bannerComponent ? bannerComponent : <></>}
                    </div>
                    <div className={`flex justify-center w-full h-full min-h-[80vh] ${bgColor}`}>
                        <div className='flex flex-col w-full md:w-2/3 lg:w-2/3 xl:w-7/12 2xl:w-1/2 h-full '>
                            {children}
                        </div>
                    </div>
                </div>
            </div>

            <UserFooterNew />
            
            <div className='md:hidden w-full bottom-0 sticky'>
                <UserMobileNavFooter/>
            </div>
            <div className={`
                ${isVisible ? '' : 'hidden'}
            `}>
                <UserFeedbackWidget />
            </div>
        </div>
    )
}