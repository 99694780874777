//packages
import React,{useEffect, useState} from 'react';
import Link from 'next/link'
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
//hi icons
import { HiOutlineMenu } from "react-icons/hi";
import { HiOutlineArrowLeft } from "react-icons/hi";

//components v3
import { LoginSignupModal } from '@/src/components-v3/modals-drawers/login-signup-modal/LoginSignupModal';

//atoms
import { HeaderLogo } from '@/src/atoms-v3/headerLogo';
import { Alert } from '@/src/atoms/alert';

//header components
// import { NotificationDropdown } from '@/src/components/dropdowns/notification-dropdown/NotificationDropdown';
import { NotificationDropdown } from '../dropdowns/notification-dropdown/NotificationDropdown2';
import { LanguageDropdown } from '@/src/components-new/dropdown/language-dropdown/LanguageDropdown';
import { UserDrawerMenu } from '../menus/user-drawer-menu/UserDrawerMenu';
import { SimpleButton } from '@/src/atoms-v3/button/SimpleButton';
import { DisplayProfilePicture } from '@/src/atoms-v3/image/DisplayProfilePicture';

//redux
import { useAppSelector } from '@/src/hooks/useSelector';


interface UserHeaderProps {
    // l?: 'en'|'jp';
    pageTitle?: string;
    backHandler?: () => void;
}
export const UserHeader = ({
    // l='jp'
    pageTitle='',
    backHandler
}: UserHeaderProps) => {
    const router = useRouter();
    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    //responsive
    const smallScreen = useMediaQuery({ maxWidth:640 })
    const isMobile = useMediaQuery({ maxWidth:768 })
    const isBigScreen = useMediaQuery({ minWidth:1024 })


    const userData = useAppSelector(state => state.user).data
    const {token, is_organizer, profilePicture, username, email} = userData

    // user type: guest, user, or organizer
    const isUser = token ? true : false
    const isOrganizer = isUser && is_organizer ? true : false

    /**
     *  SEARCH LESSON & ORGANIZER BUTTONS
    */    
    const searchLessonHandler = () => {
        router.push('/search-lessons')
    }
    const searchOrganizerHandler = () => {
        router.push('/search-organizers')
    }

    /**
     * Success Banner state
    */
    const [successBannerText, setSuccessBannerText] = useState('')

    /** LOGIN SIGNUP MODAL */
    const [loginSignupVisible, setLoginSignupVisible] = useState(false)

    /** DRAWER MENU VISIBLE STATE */
    const [drawerMenuVisible, setDrawerMenuVisible] = useState(false)

    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        lesson:{
            en:'Search Dance Lessons',
            jp:'ダンスレッスン検索'
        },
        organizer:{
            en:'Organizers',
            jp:'主催者'
        },
        switch:{
            en:"Switch to Organizer",
            jp:'主催者アカウントに切り替える'
        },
        teach:{
            en:"Become an Organizer",
            jp:'教えたい方'
        },
        login:{
            en:'Login',
            jp:'ログイン'
        },
        signup:{
            en:'Create Account',
            jp:'新規登録'
        }
    }


    return (
        <div>
            <div className={`
                sticky top-0 bg-white border-b border-gray-300
                w-full md:h-[70px] 
                flex flex-row justify-between items-center relative
                px-3 py-3
            `}>
                <div className='flex flex-row gap-2 items-center'>
                    {   !isBigScreen && (
                        backHandler ? (
                            <HiOutlineArrowLeft 
                                size={24}
                                onClick={() => backHandler()}
                            />
                        ) : (
                            <HiOutlineMenu 
                                size={24}
                                onClick={() => setDrawerMenuVisible(true)}
                            />
                        )
                    )}
                    
                    <div className='justify-self-start pl-3'>
                        {   pageTitle && !isBigScreen ? (
                            <h1>{pageTitle}</h1>
                        ) : (
                            <Link href='/'>
                                <HeaderLogo />
                            </Link>
                        )}
                    </div>
                </div>


                {/** Middle (large screen only) */}
                <div className=' justify-self-center hidden md:flex flex-row gap-2 sm:gap-10'>
                    <div className='flex items-center' 
                        onClick={searchLessonHandler}
                    >
                        <p 
                            className='block text-black cursor-pointer font-medium text-base'
                            >{text['lesson'][l]}
                        </p>
                    </div>
                </div>


                <div className='flex flex-row gap-3 mr-2 md:mr-3 items-center justify-self-end'>
                    {/** big screen only */}
                    {   isOrganizer ? (
                        <Link href='/organizer/home' className='cursor-pointer hidden lg:block'>
                            <p className='text-sm text-gray-700 md:text-[16px]'>{text['switch'][l]}</p>
                        </Link>
                    ) : (
                        <Link href='/join-ambition/organizer' className='cursor-pointer hidden lg:block'>
                            <p className='text-sm text-gray-700 md:text-[16px]'>{text['teach'][l]}</p>
                        </Link>
                    )}

                    {  (isUser && isBigScreen) ? (
                        <>
                            {/** USER && BIG SCREEN */}
                            <NotificationDropdown />
                            {/** DISPLAY ICON */}
                            <DisplayProfilePicture 
                                height='h-[40px]'
                                image={profilePicture}
                                onClick={() => setDrawerMenuVisible(true)}
                                alt={username}
                                placeholderColor='orange'
                                placeholderLetter={username[0].toUpperCase()}
                            />

                        </>
                    ) : (isUser && !isBigScreen) ? (
                        <>
                            {/** USER && SMALL SCREEN */}
                            <NotificationDropdown />
                        </>

                    ) : (!isUser && isBigScreen) ? (
                        <>
                            {/** GUEST && BIG SCREEN */}
                            <div className='flex flex-row gap-3 pr-3 items-center'>
                                <LanguageDropdown />

                                <SimpleButton 
                                    onClick={() => setLoginSignupVisible(true)} 
                                    color='black' bg='outline'
                                    className='px-6 py-2'
                                >
                                    <p className='font-medium'>
                                        Login
                                    </p>
                                </SimpleButton>

                                <SimpleButton 
                                    onClick={() => setLoginSignupVisible(true)} 
                                    color='orange' bg='solid'
                                    className='px-6 py-2'
                                >
                                    <p className='font-medium'>
                                        Sign Up
                                    </p>
                                </SimpleButton>
                            </div>
                        </>
                    ) : (!isUser && !isBigScreen) ? (
                        <>
                            {/** GUEST && SMALL SCREEN */}
                            <div className='flex flex-row gap-2 items-center'>
                                <LanguageDropdown />
                                <SimpleButton 
                                    onClick={() => setLoginSignupVisible(true)} 
                                    color='orange' bg='solid'
                                    className='px-6 py-2'
                                >
                                    <p className='font-medium'>
                                        Login/Signup
                                    </p>
                                </SimpleButton>
                            </div>
                        </>
                    ): (<></>)}
                </div>
            </div>
            
            {/**BANNER GOES HERE */}
            <div className={successBannerText ? 'relative z-[-1]' : 'hidden'}>
                <Alert 
                    isVisible={successBannerText ? true : false}
                    hideAlert={() => setSuccessBannerText('')}
                    message={successBannerText}
                    type='success'
                    showCloseButton={true}
                    autoClose={false}
                    zIndex={-1}
                />
            </div>

            <LoginSignupModal 
                isVisible={loginSignupVisible}
                hideModalCallback={() => setLoginSignupVisible(false)}
            />

            <UserDrawerMenu 
                isVisible={drawerMenuVisible}
                hideDrawerCallback={() => setDrawerMenuVisible(false)}
                is_left={isBigScreen ? false : true}
            />

        </div>
    )
}
