
import { useRef, useState, useEffect } from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";



interface NavTabsProps {
    tabList: {
        label:string, 
        value:string
    }[]
    onClick: (val:string) => void;
    selected:string // value
}

export const NavTabs = ({
    tabList, 
    onClick,
    selected
}:NavTabsProps) => {
    // const containerRef = useRef<HTMLUListElement>(null);
    // const [showLeftArrow, setShowLeftArrow] = useState(false);
    // const [showRightArrow, setShowRightArrow] = useState(false);
  

    // const checkScroll = () => {
    //     if (containerRef.current) {
    //       const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    //       setShowLeftArrow(scrollLeft > 0);
    //       setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    //     }
    //   };

    //   const scrollContainer = (direction: "left" | "right") => {
    //     if (containerRef.current) {
    //       const scrollAmount = 100; // Adjust scroll distance
    //       const currentScroll = containerRef.current.scrollLeft;
    
    //       console.log("Before Scroll:", currentScroll);
    //       containerRef.current.scrollBy({
    //         left: direction === "left" ? -scrollAmount : scrollAmount,
    //         behavior: "smooth",
    //       });
    
    //       setTimeout(() => {
    //         console.log("After Scroll:", containerRef.current?.scrollLeft);
    //       }, 200);
    //     }
    //   };
    

    //   useEffect(() => {
    //     checkScroll();
    
    //     const container = containerRef.current;
    //     if (container) {
    //       container.addEventListener("scroll", checkScroll);
    //       window.addEventListener("resize", checkScroll); // Ensure responsiveness
    //     }
    
    //     return () => {
    //       if (container) container.removeEventListener("scroll", checkScroll);
    //       window.removeEventListener("resize", checkScroll);
    //     };
    //   }, []);
    



    return (
        <div className="relative text-sm font-medium text-center text-gray-500 border-b border-gray-200 bg-white w-full">
            {/* Left Arrow */}
            {/* {showLeftArrow && (
                <button
                onClick={() => scrollContainer("left")}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 shadow rounded-full"
                >
                    <HiOutlineChevronLeft className="w-5 h-5 text-gray-500" />
                </button>
            )} */}
            {/** TABS */}
            <div className="overflow-x-auto overflow-y-hidden relative ">
                <ul 
                    // ref={containerRef}
                    className="flex flex-nowrap -mb-px space-x-4 scrollbar-hide"
                >
                    {   tabList.length ? (
                        tabList.map((tab) => {
                            return (
                                <li 
                                    onClick={() => onClick(tab.value)}
                                    className="me-2" 
                                    key={tab.value}
                                >
                                    <p className={`
                                        inline-block p-4 rounded-t-lg cursor-pointer
                                        ${ selected.toLowerCase()==tab.value.toLowerCase() ? 
                                            'text-blue-600 border-b-2 border-blue-600' : 
                                            'border-transparent hover:text-gray-600 hover:border-gray-300'}
                                    `}>
                                        {tab.label}
                                    </p>
                                </li>
                            )
                        })
                    ) : (<></>)
                    }
                </ul>
            </div>

             {/* Right Arrow */}
            {/* {showRightArrow && (
                <button
                    onClick={() => scrollContainer("right")}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 shadow rounded-full"
                >
                    <HiOutlineChevronRight className="w-5 h-5 text-gray-500" />
                </button>
            )} */}
        </div>
    )
}